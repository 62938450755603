import React, { useState } from "react";
// import blog3Data from "data/blog3.json";
import DarkTheme from "layouts/Dark";
import Navbar from "components/Navbar/navbar";
import BlogGrid from "components/Blog-grid/blog-grid.jsx";
import Footer from "components/Footer/footer";
import { GetBlogPostData } from "components/ApiServices/GetBlogPostsdata";
import BlogsHeader from "components/Blogs-header/blogs-header";
import SEO from "components/seo/seo";
import LoadingScreen from "components/Loading-Screen";

const BlogGridDark = () => {
  const blogData = GetBlogPostData();
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  const MainContent = React.useRef(null);
  const fixedHeader = React.useRef(null);

  const [theme, setTheme] = useState("Dark");

  React.useEffect(() => {
    var navbar = navbarRef.current;

    setTimeout(() => {
      if (fixedHeader.current) {
        var slidHeight = fixedHeader.current.offsetHeight;
      }
      if (MainContent.current) {
        MainContent.current.style.marginTop = slidHeight + "px";
      }
    }, 1000);

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });

    var storage = localStorage.getItem("theme");

    if (storage && storage != "") {
      let bodyEl = document.querySelector("body");
      bodyEl.classList.add("themeL");
      setTheme(storage);
    }
  }, [fixedHeader, navbarRef, MainContent]);

  const themeModeChange = (themeMode) => {
    let bodyEl = document.querySelector("body");
    if (themeMode == "Dark") {
      setTheme("themeL");
      bodyEl.classList.add("themeL");
      localStorage.setItem("theme", "themeL");
    } else {
      setTheme("Dark");
      bodyEl.classList.remove("themeL");
      localStorage.removeItem("theme");
    }
  };

  return (
    <DarkTheme>
      <LoadingScreen/>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar
        nr={navbarRef}
        lr={logoRef}
        theme={theme}
        themeModeChange={themeModeChange}
      />
      <BlogsHeader sliderRef={fixedHeader} />
      <div ref={MainContent} className="main-content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10">
              <div className="sec-head text-center mt-10">
                <h6 className="wow fadeIn" data-wow-delay=".5s">
                  News
                </h6>
                <h3 className="wow color-font">Our Recent News.</h3>
              </div>
            </div>
          </div>
        </div>
        <BlogGrid blogs={blogData} />
        <Footer />
      </div>
    </DarkTheme>
  );
};

export const Head = () => {
  const liveUrl = typeof window !== "undefined" ? window.location.href : "";

  return (
    <>
      <SEO
        canonical={liveUrl}
        title="Latest Digital transformation, Web & Mobile Technology Blog |Evolvan"
        description="Evolavn's official blogs for the latest in news, technology, and web & mobile app development updates. Stay informed with our curated feeds and insightful blog posts."
      />
    </>
  );
};

export default BlogGridDark;
